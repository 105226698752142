/* eslint-disable indent */
/* eslint-disable space-infix-ops */
import styled from 'styled-components';
import { device, Common } from '@stockbit/ui';
import {
  fadeIn,
  fromRight,
  fadeInRight,
  fadeInLeft,
  fadeInTop,
  fadeInDown,
  scaleUp,
} from './keyframes';

const { Flex, Text } = Common;

const Wrapper = styled(Flex).attrs({
  direction: 'column',
  m: 'auto',
  as: 'section',
})`
  padding: 50px 25px;
  width: 100%;
  max-width: 1300px;

  @media ${device.tablet} {
    padding: 50px 30px;
  }

  @media ${device.laptop} {
    padding: 50px 0;
    width: calc(100% - 150px);
  }

  // animations
  .scaleUpX {
    transform: scale(0.9);
  }

  .fadeInRightX,
  .fromRightX,
  .fadeInLeftlongX,
  .fadeInLeftBorderX,
  .fadeInLeftX2,
  .fadeInLeftX,
  .fadeInX,
  .fadeInTopX,
  .fadeInDownX,
  .scaleUpX {
    opacity: 0;
  }

  .fadeInLeftBorderX.on,
  .fadeInLeftX.on,
  .fadeInX.on {
    opacity: 1;
  }

  .fadeIn {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-name: ${fadeIn};
    transition-timing-function: ease-out;
  }

  .fromRight {
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
    animation-name: ${fromRight};
    transition-timing-function: ease-out;
  }

  .fadeInRight {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-name: ${fadeInRight};
    transition-timing-function: ease-out;
  }

  .fadeInLeft {
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
    animation-name: ${fadeInLeft};
    transition-timing-function: ease-out;
  }

  .fadeInLeft2 {
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
    animation-name: ${fadeInLeft};
    transition-timing-function: ease-out;
    animation-delay: 0.3s;
  }

  .fadeInTopX {
  }

  .fadeInTop {
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-name: ${fadeInTop};
    transition-timing-function: ease-out;
    transform: translate3d(0, -50px, 0);
  }

  .fadeInDown {
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-name: ${fadeInDown};
    transition-timing-function: ease-out;
    transform: translate3d(0, -50px, 0);
  }

  .fromRightP:nth-child(1) .fromRight {
    animation-delay: 0.2s;
  }

  .fromRightP:nth-child(2) .fromRight {
    animation-delay: 0.35s;
  }

  .fromRightP:nth-child(3) .fromRight {
    animation-delay: 0.5s;
  }

  .fromRightP:nth-child(4) .fromRight {
    animation-delay: 0.65s;
  }

  .fromRightP:nth-child(5) .fromRight {
    animation-delay: 0.8s;
  }

  .fromRightP:nth-child(6) .fromRight {
    animation-delay: 0.95s;
  }

  .scaleUp {
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
    animation-name: ${scaleUp};
    transition-timing-function: ease-out;
  }
`;

const SectionTitle = styled(Text).attrs({
  as: 'h1',
  family: 'bold',
  height: 1,
  m: '0px',
})`
  font-size: 36px;
  text-align: center;

  @media ${device.laptop} {
    font-size: 6.5vh;
  }
`;

const SectionSubtitle = styled(Text).attrs({
  family: 'light',
  height: 1.5,
})`
  font-size: 17px;
  margin-top: 5vh;
  text-align: center;

  @media ${device.tablet} {
    font-size: 18px;
  }

  @media ${device.laptop} {
    font-size: 19px;
  }
`;

export { Wrapper, SectionTitle, SectionSubtitle };
