/* eslint-disable space-infix-ops */
import styled from 'styled-components';
import { Common, device } from '@stockbit/ui';
import Image from 'global/Image';

const { Text } = Common;

const SectionHeader = styled(Text).attrs({
  family: 'bold',
  align: 'center',
  as: 'h2',
})`
  font-size: 25.5px;
  margin-bottom: 20px;

  @media ${device.tablet} {
    font-size: 28.5px;
    margin-bottom: 40px;
  }
`;

const FeaturedWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;
  margin: auto;
  max-width: 870px;

  @media ${device.tablet} {
    grid-template-columns: repeat(4, 1fr);

    a {
      align-items: center;
      display: flex;
    }
  }

  @media ${device.laptop} {
    grid-gap: 30px;
    padding: 0 30px;
  }
`;

interface FeaturedImageProps {
  height: string;
}

const FeaturedImage = styled(Image)<FeaturedImageProps>`
  display: block;
  height: auto;
  margin: auto;
  width: 100%;
  max-width: 120px;

  @media ${device.laptop} {
    height: ${({ height }) => height};
    width: auto;
    max-width: unset;
  }
`;

export { SectionHeader, FeaturedImage, FeaturedWrapper };
